import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useAuthStore } from "@/stores/auth";
import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { getCurrentOrganizationKey } from "../lib/config";

const strictOrganizationGuardPages = ["login", "register", "forgot-password", "reset-password"];

export async function organizationGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
): Promise<void> {
    const authStore = useAuthStore();
    const { isSystemAdmin } = useUserPrivileges();

    // Used variables
    const subDomainOrgKey = getCurrentOrganizationKey();
    const hasSelectedOrganization = !!authStore.selectedOrganization;
    const isStrictDestination = strictOrganizationGuardPages.includes(to.name as string);

    // Si on est sur la page d'erreur d'organisation, on la laisse s'afficher
    if (to.name === "organization-error") {
        return next();
    }

    // Handle organization selection
    if (subDomainOrgKey) {
        if (hasSelectedOrganization) {
            if (subDomainOrgKey === authStore.selectedOrganization!.key) {
                return next();
            } else {
                if (isSystemAdmin.value) {
                    if (isStrictDestination) {
                        try {
                            const organization = await authStore.getOrganizationByKey(subDomainOrgKey);
                            if (organization) {
                                authStore.setSelectedOrganization(organization);
                                return next();
                            }
                        } catch {
                            return next({ name: "organization-error" });
                        }
                    }
                    return next();
                }
                authStore.clearSelectedOrganization();
                authStore.redirectToOrganization(authStore.selectedOrganization!);
            }
        } else {
            try {
                const organization = await authStore.getOrganizationByKey(subDomainOrgKey);
                if (organization) {
                    authStore.setSelectedOrganization(organization);
                    return next();
                }
                authStore.clearSelectedOrganization();
                return next({ name: "organization-error" });
            } catch {
                return next({ name: "organization-error" });
            }
        }
    } else {
        authStore.clearSelectedOrganization();
        if (to.name === "selectOrganization" || to.name === "onboarding") {
            return next();
        }
        return next({ name: "selectOrganization" });
    }
}
