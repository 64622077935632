import { useAuthStore } from "@/stores/auth";
import { toast } from "vue-sonner";

export class ApiError extends Error {
    constructor(
        message: string,
        public statusCode: number,
        public details?: any,
    ) {
        super(message);
        this.name = "ApiError";
    }
}

const INVALID_TOKEN_ERROR_CODE = 417;

export const handleFetchError = (error: unknown): ApiError => {
    const authStore = useAuthStore();

    if (error instanceof ApiError && error.statusCode === INVALID_TOKEN_ERROR_CODE) {
        // Clear all authentication data
        localStorage.clear();
        authStore.signOut();

        // Show error message
        toast.error(
            "L'erreur peut provenir d'une défaillance de la base de données mais plus probablement d'un cache invalide côté client. Veuillez vider votre cache et vous reconnecter.",
        );
    }

    // Rethrow the error for further handling if needed
    throw error;
};
