<script setup lang="ts">
import { Button } from "@/components/ui/button";
import {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { cn } from "@/lib/utils";
import { useAuthStore } from "@/stores/auth";
import { useMagicKeys } from "@vueuse/core";
import type { LucideIcon } from "lucide-vue-next";
import {
    Briefcase,
    Calendar,
    CalendarPlusIcon,
    Clock7Icon,
    ClockIcon,
    CoinsIcon,
    DollarSignIcon,
    Euro,
    EuroIcon,
    FileText,
    Headphones,
    Home,
    HomeIcon,
    LocateFixed,
    LogOut,
    MailsIcon,
    Menu,
    PlusIcon,
    SearchIcon,
    SettingsIcon,
    Undo2Icon,
    User,
    UserPlusIcon,
    Users,
    UsersIcon,
} from "lucide-vue-next";
import { computed, ref, watch } from "vue";
import type { RouteLocationRaw, RouteRecordName } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import ProfileAvatar from "./ProfileAvatar.vue";
import SheetLink from "./global/links/SheetLink.vue";
import LegalAcceptanceDialog from "./legal/LegalAcceptanceDialog.vue";
import NotificationsWarnings from "./warnings/NotificationsWarnings.vue";

// Types
interface BaseLink {
    title: string;
    icon: LucideIcon;
    routeName: Exclude<RouteRecordName, null | undefined>;
    params?: Record<string, string>;
}

interface RegularLink extends BaseLink {
    isDropdown?: false;
}

interface DropdownLink extends BaseLink {
    isDropdown: true;
    subLinks: Array<{
        title: string;
        routeName: Exclude<RouteRecordName, null | undefined>;
        params?: Record<string, string>;
    }>;
}

type LinkType = RegularLink | DropdownLink;

// Composables
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

// User privileges and state
const { isSystemAdmin, isSystemAdminOrganisation, isSupport, isMoreOrEqualToEstManager, isMoreOrEqualToOrgManager } =
    useUserPrivileges();

const userRole = computed(() => authStore.userRole);
const selfMember = computed(() => authStore.selfMember);
const selectedOrganization = computed(() => authStore.selectedOrganization);
const userOrganization = computed(() => authStore.userOrganization);

// Computed properties
const displayProfileLink = computed(() => selectedOrganization.value?.id === userOrganization.value?.id);

// Navigation actions
const signOut = () => {
    authStore.signOut();
    router.push({ name: "login" });
};

// Base navigation links
const baseLinks: RegularLink[] = [
    { title: "Accueil", icon: Home, routeName: "index" },
    { title: "Documents", icon: FileText, routeName: "documents" },
    { title: "Factures", icon: Euro, routeName: "orders" },
    { title: "Communications", icon: Headphones, routeName: "communication" },
];

const servicesLink = (isUser: boolean): LinkType =>
    isUser
        ? { title: "Services", icon: Briefcase, routeName: "services" }
        : { title: "Services", icon: Briefcase, routeName: "service-blueprints" };

const membersLink: DropdownLink = {
    title: "Membres",
    icon: Users,
    routeName: "members",
    isDropdown: true,
    subLinks: [
        { title: "Tous", routeName: "members" },
        { title: "Titulaires", routeName: "holders" },
        { title: "Participants", routeName: "participants" },
    ],
};

const configurationLink = (establishmentId: string, organizationId: string): RegularLink => ({
    title: "Configuration",
    icon: SettingsIcon,
    routeName: userRole.value === "est-manager" ? "configuration-establishments" : "configuration-organizations",
    params: userRole.value === "est-manager" ? { id: establishmentId } : { id: organizationId },
});

// Dropdown state
const isServicesDropdownOpen = ref(false);
const isMembersDropdownOpen = ref(false);

const toggleServicesDropdown = () => {
    isServicesDropdownOpen.value = !isServicesDropdownOpen.value;
};

const toggleMembersDropdown = () => {
    isMembersDropdownOpen.value = !isMembersDropdownOpen.value;
};

// Active link helpers
const isLinkActive = (routeName: RouteRecordName, params?: Record<string, string>) => {
    if (routeName === "index") {
        return route.name === "index";
    }

    if (params) {
        return route.name === routeName && Object.entries(params).every(([key, value]) => route.params[key] === value);
    }

    return route.name === routeName;
};

// Additional links based on user role
const getAdditionalActionsLinks = computed((): RegularLink[] => {
    if (!isMoreOrEqualToEstManager.value) return [];

    return [
        {
            title: "Créer un établissement",
            icon: PlusIcon,
            routeName: "establishment-create",
        },
        {
            title: "Créer un service",
            icon: PlusIcon,
            routeName: "service-create",
        },
        {
            title: "Créer une période",
            icon: CalendarPlusIcon,
            routeName: "period-create",
        },
        {
            title: "Ajouter un membre",
            icon: UserPlusIcon,
            routeName: "member-create",
        },
    ];
});

// Main navigation links based on user role
const links = computed((): LinkType[] => {
    if (isSystemAdminOrganisation.value && isSupport.value) {
        return [
            { title: "Organisations", icon: HomeIcon, routeName: "organizations" },
            { title: "Administrateurs et supports", icon: UsersIcon, routeName: "members" },
            { title: "Contacter l'administration", icon: MailsIcon, routeName: "contact-support" },
        ];
    }

    if (isSystemAdminOrganisation.value && isSystemAdmin.value) {
        return [
            { title: "Organisations", icon: HomeIcon, routeName: "organizations" },
            { title: "Administrateurs et supports", icon: UsersIcon, routeName: "members" },
            { title: "Facturation", icon: EuroIcon, routeName: "billing" },
            { title: "Communication", icon: Headphones, routeName: "communication" },
            configurationLink(authStore.currentEstablishmentId, selectedOrganization.value?.id || ""),
        ];
    }

    const isUser = userRole.value === "user";
    const isHolder = userRole.value === "user" && selfMember.value?.isHolder;
    const isEstManager = userRole.value === "est-manager";

    if (isUser && !isHolder) {
        return [
            baseLinks[0],
            servicesLink(true),
            { title: "Famille", icon: Users, routeName: "delegations" },
            { title: "Jetons de récupération", icon: CoinsIcon, routeName: "recovery-tokens" },
            { title: "Historique des activités", icon: Clock7Icon, routeName: "registry" },
            ...baseLinks.slice(1),
        ];
    }

    if (isHolder) {
        return [
            baseLinks[0],
            servicesLink(true),
            { title: "Participants", icon: Users, routeName: "participants" },
            { title: "Historique des activités", icon: Clock7Icon, routeName: "registry" },
            ...baseLinks.slice(3),
        ];
    }

    const commonLinks: LinkType[] = [
        baseLinks[0],
        servicesLink(false),
        membersLink,
        { title: "Périodes", icon: Calendar, routeName: "periods" },
    ];

    if (isEstManager) {
        return [
            ...commonLinks,
            ...baseLinks.slice(1, 2),
            ...baseLinks.slice(3),
            { title: "Jetons de récupération", icon: CoinsIcon, routeName: "recovery-tokens" },
            { title: "Comptabilité", icon: DollarSignIcon, routeName: "accountability" },
            { title: "Heures prestées", icon: ClockIcon, routeName: "hours-worked" },
            configurationLink(authStore.currentEstablishmentId, selectedOrganization.value?.id || ""),
        ];
    }

    if (isMoreOrEqualToOrgManager.value && !isSystemAdminOrganisation.value) {
        const links = [
            ...commonLinks,
            { title: "Jetons de récupération", icon: CoinsIcon, routeName: "recovery-tokens" },
            { title: "Établissements", icon: LocateFixed, routeName: "establishments" },
            { title: "Comptabilité", icon: DollarSignIcon, routeName: "accountability" },
            { title: "Heures prestées", icon: ClockIcon, routeName: "hours-worked" },
            ...baseLinks.slice(1, 2),
            ...baseLinks.slice(3),
            configurationLink(authStore.currentEstablishmentId, selectedOrganization.value?.id || ""),
        ];

        return isSupport.value ? links.filter(l => l.title !== "Comptabilité") : links;
    }

    return baseLinks;
});

// Command palette
const isCommandOpen = ref(false);
const keys = useMagicKeys();
const CmdK = keys["Cmd+K"];

const toggleCommand = () => {
    isCommandOpen.value = !isCommandOpen.value;
};

watch(CmdK, v => {
    if (v) {
        toggleCommand();
    }
});

const allCommandItems = computed(() => {
    return links.value.flatMap(link => {
        if (link.isDropdown) {
            return link.subLinks.map(subLink => ({
                title: `${link.title} - ${subLink.title}`,
                routeName: subLink.routeName,
                params: subLink.params,
                icon: link.icon,
            }));
        }
        return [
            {
                title: link.title,
                routeName: link.routeName,
                params: link.params,
                icon: link.icon,
            },
        ];
    });
});

const navigateToItem = (routeName: RouteRecordName, params?: Record<string, string>) => {
    router.push({ name: routeName, params } as RouteLocationRaw);
    isCommandOpen.value = false;
};

// Admin navigation
const handleGoBackAdmin = () => {
    if (!isSystemAdmin.value) return;
    authStore.exitSysAdminMode();
    router.push({ name: "organizations" });
};

// Sheet control
const isSheetOpen = ref(false);

const closeSheet = () => {
    isSheetOpen.value = false;
};
</script>

<template>
    <aside
        :class="
            cn(
                'left-0 top-0 hidden h-screen w-72 flex-col items-start justify-between gap-6 px-12 py-10 text-white lg:flex',
                !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark',
            )
        ">
        <router-link
            :to="{ name: 'index' }"
            class="mx-auto inline-block w-full max-w-[100px]"
            title="Retour à l'accueil">
            <img src="/logo/light-small.png" class="mx-auto inline-block w-full max-w-[100px]" alt="Logo Rainbow Top" />
        </router-link>

        <!-- Main Navigation -->
        <nav class="flex grow overflow-y-auto">
            <ul class="flex grow flex-col gap-4">
                <li v-for="link in links" :key="link.title">
                    <SheetLink
                        :link="link"
                        :is-services-dropdown-open="isServicesDropdownOpen"
                        :is-members-dropdown-open="isMembersDropdownOpen"
                        :toggle-services-dropdown="toggleServicesDropdown"
                        :toggle-members-dropdown="toggleMembersDropdown"
                        :is-link-active="isLinkActive"
                        @close="closeSheet" />
                </li>
            </ul>
        </nav>

        <!-- Footer -->
        <footer
            :class="
                cn('flex w-full grow flex-col items-start justify-end', {
                    'gap-8': (!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation,
                    'gap-3': !isSystemAdminOrganisation && isSystemAdmin,
                })
            ">
            <!-- Notifications -->
            <div v-if="isMoreOrEqualToOrgManager && !isSystemAdminOrganisation" class="w-full">
                <NotificationsWarnings />
            </div>

            <!-- System Admin Info -->
            <div v-if="!isSystemAdminOrganisation && isSystemAdmin" class="flex w-full flex-col items-start gap-2">
                <div class="flex flex-col">
                    <p class="text-sm">Connecté à</p>
                    <p class="text-sm">{{ selectedOrganization?.name }}</p>
                </div>
            </div>

            <!-- Profile Link -->
            <div v-if="displayProfileLink" class="flex w-full items-center gap-4">
                <router-link
                    :to="{ name: 'profile' }"
                    class="group flex w-full flex-grow flex-row flex-nowrap items-center gap-4 text-white">
                    <ProfileAvatar class="group-hover:bg-secondary/80 transition-colors" />
                    <span
                        class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors group-hover:text-white/80">
                        Mon profil
                    </span>
                </router-link>
                <Button variant="ghost" size="icon" @click="toggleCommand" class="hover:text-secondary text-white">
                    <SearchIcon :size="16" />
                </Button>
            </div>

            <!-- Action Buttons -->
            <Button
                v-if="(!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation"
                variant="secondary"
                @click="signOut"
                class="flex w-full items-center justify-center gap-2 py-2">
                <LogOut :size="20" />
                Se déconnecter
            </Button>
            <Button
                v-else-if="!isSystemAdminOrganisation && isSystemAdmin"
                variant="secondary"
                @click="handleGoBackAdmin"
                class="flex w-full items-center justify-center gap-2 py-2">
                <Undo2Icon :size="20" />
                Quitter
            </Button>
        </footer>
    </aside>

    <!-- Mobile Navigation Sheet -->
    <Sheet v-model:open="isSheetOpen">
        <div
            :class="
                cn(
                    'flex items-center justify-between p-4 lg:hidden',
                    !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark',
                )
            ">
            <router-link :to="{ name: 'index' }" class="flex items-center gap-2">
                <img
                    src="/logo/circle-white.png"
                    class="inline-flex size-10 items-center object-contain lg:hidden"
                    alt="Logo Rainbow Top" />
            </router-link>

            <div class="flex items-center gap-6">
                <SheetTrigger as-child>
                    <Button
                        class="flex shrink-0 items-center gap-2 bg-transparent px-0 lg:hidden"
                        @click="isSheetOpen = true">
                        Menu
                        <Menu :size="30" />
                        <span class="sr-only">Ouvrir le menu de navigation</span>
                    </Button>
                </SheetTrigger>

                <!-- Logout/Quit button -->
                <Button
                    v-if="(!isSystemAdminOrganisation && !isSystemAdmin) || isSystemAdminOrganisation"
                    variant="secondary"
                    @click="signOut"
                    class="flex items-center justify-center gap-2 py-2">
                    <LogOut :size="20" />
                </Button>
                <Button
                    v-else-if="!isSystemAdminOrganisation && isSystemAdmin"
                    variant="secondary"
                    @click="handleGoBackAdmin"
                    class="flex items-center justify-center gap-2 py-2">
                    <Undo2Icon :size="20" />
                </Button>
            </div>
        </div>
        <SheetContent
            side="left"
            :class="
                cn('bg-primary border-primary', !isSystemAdminOrganisation && isSystemAdmin ? 'bg-red-700' : 'bg-dark')
            ">
            <nav class="flex h-full flex-col gap-10 text-lg font-medium">
                <router-link :to="{ name: 'index' }" class="flex items-center gap-2">
                    <img
                        src="/logo/light-small.png"
                        class="mx-auto inline-block w-full max-w-[100px]"
                        alt="Logo Rainbow Top" />
                </router-link>
                <ul class="flex shrink flex-col gap-4">
                    <li v-for="link in links" :key="link.title">
                        <SheetLink
                            :link="link"
                            :is-services-dropdown-open="isServicesDropdownOpen"
                            :is-members-dropdown-open="isMembersDropdownOpen"
                            :toggle-services-dropdown="toggleServicesDropdown"
                            :toggle-members-dropdown="toggleMembersDropdown"
                            :is-link-active="isLinkActive"
                            @close="closeSheet" />
                    </li>
                </ul>

                <footer class="flex grow flex-col items-start justify-end gap-4">
                    <router-link
                        v-if="displayProfileLink"
                        :to="{ name: 'profile' }"
                        class="group flex flex-row flex-nowrap items-center gap-2 text-white"
                        @click="closeSheet">
                        <component
                            :is="User"
                            class="group-hover:bg-secondary/80 size-9 overflow-visible rounded-full bg-transparent p-2 transition-colors" />
                        <span
                            class="group-hover:decoration-primary underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                            Mon profil
                        </span>
                    </router-link>

                    <Button
                        variant="secondary"
                        @click="signOut"
                        class="flex w-full items-center justify-center gap-2 py-2">
                        <LogOut :size="20" />
                        <p
                            class="group-hover:decoration-primary text-lg font-medium underline decoration-transparent decoration-2 underline-offset-4 transition-colors">
                            Se déconnecter
                        </p>
                    </Button>
                </footer>
            </nav>
        </SheetContent>
    </Sheet>

    <!-- Legal Acceptance Dialog -->
    <LegalAcceptanceDialog />

    <!-- Command Dialog -->
    <CommandDialog v-model:open="isCommandOpen">
        <Command>
            <CommandInput placeholder="Tapez une commande ou recherchez..." />
            <CommandList>
                <CommandEmpty>Aucun résultat trouvé.</CommandEmpty>
                <CommandGroup heading="Suggestions">
                    <CommandItem
                        v-for="item in allCommandItems"
                        :key="item.routeName"
                        :value="item.title"
                        @select="() => navigateToItem(item.routeName, item.params)">
                        <component :is="item.icon" class="mr-2 h-4 w-4" />
                        <span>{{ item.title }}</span>
                    </CommandItem>
                </CommandGroup>
                <CommandSeparator />
                <CommandGroup heading="Actions">
                    <CommandItem
                        v-for="link in getAdditionalActionsLinks"
                        :key="link.routeName"
                        :value="link.title"
                        @select="() => navigateToItem(link.routeName)">
                        <component :is="link.icon" class="mr-2 h-4 w-4" />
                        <span>{{ link.title }}</span>
                    </CommandItem>
                    <CommandItem @select="signOut" value="Se déconnecter">
                        <LogOut class="mr-2 h-4 w-4" />
                        <span>Se déconnecter</span>
                    </CommandItem>
                </CommandGroup>
            </CommandList>
        </Command>
    </CommandDialog>
</template>
